import { useMemo, useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from '@dialexa/reece-component-library';
import {
  useApiGetInvoiceDetails,
  useApiGetOrderDetailsV2,
  useApiGetProofOfDelivery
} from 'API/orders.api';
import Breadcrumbs from 'common/Breadcrumbs';
import Loader from 'components/Loader';
import {
  ErpSystemEnum,
  Order,
  ProofOfDeliveryResponse
} from 'generated/graphql';
import { PrintIcon, WarningIcon } from 'icons';
import ProofOfDeliveryModal from 'Invoice/ProofOfDeliveryModal';
import OrderDetailsDelivery from 'pages/Orders/components/OrderDetailsDelivery';
import OrderDetailsRelated from 'pages/Orders/components/OrderDetailsRelated';
import OrderDetailsStatus from 'pages/Orders/components/OrderDetailsStatus';
import OrderDetailsSummary from 'pages/Orders/components/OrderDetailsSummary';
import ProductsCard from 'pages/Orders/components/ProductsCard';
import ProductsCardMobile from 'pages/Orders/components/ProductsCardMobile';
import { useListsContext } from 'providers/ListsProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useEmbeddedContext } from 'providers/EmbeddedProvider';
import { useAuthContext } from 'AuthProvider';
import { embedding } from 'utils/embedPage';
import { ProductList } from 'API/types/orders.types';

/** Types Order and ProductPricing on some sections of the new orders work are using generated/graphql to be able to reuse components
 * that are still needed, specially for the <ProductCard> component that will be added in ticket MAX-7077. This also affects MAX-7216.
 * Will be adressed with a more updated code in the future to not depend on these versions.
 */

function OrderDetails() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { id: orderIdParam } = useParams<{ id: string }>();
  const breadcrumbConfig = {
    text: t('common.orders'),
    to: `/orders/${localStorage.getItem('filters') || ''}`
  };
  const history = useHistory();

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const erpName = selectedAccounts?.erpSystemName ?? ErpSystemEnum.Eclipse;
  const billTo = selectedAccounts?.billTo?.erpAccountId ?? '';
  const { isMincron } = useSelectedAccountsContext();
  const { callGetAssociatedLists } = useListsContext();
  const { embeddedSession } = useEmbeddedContext();
  const { oktaAuth, authState } = useAuthContext();

  // if accessing this page via embed link, get order ID from the path stored in embeddedSession
  const orderIdPattern = /\/order\/([a-zA-Z0-9]+)/;
  const embeddedOrderId = embeddedSession.path?.match(orderIdPattern)?.[1];
  const id = embeddedOrderId || orderIdParam;
  const isInvoice = id.includes('.');

  /**
   * State
   */
  const [proofOfDelivery, setProofOfDelivery] =
    useState<ProofOfDeliveryResponse>();
  const [proofOfDeliveryModalOpen, setProofOfDeliveryModalOpen] =
    useState(false);

  /**
   * API
   */

  // 🟣 Lazy API - get specific order detail
  const { called, loading, data } = useApiGetOrderDetailsV2(
    {
      skip: !(id && !isInvoice),
      onCompleted: ({ data }) => {
        const productIds = data.productList.products.map(
          (product: ProductList) => product.erpPartNumber
        );
        callGetAssociatedLists({ products: productIds });
      }
    },
    { orderNumber: id, erpName, billTo }
  );

  // 🟣 Lazy API - get specific order detail
  const {
    called: callInvoice,
    loading: loadingInvoice,
    data: dataInvoice
  } = useApiGetInvoiceDetails(
    {
      skip: !(id && isInvoice),
      onCompleted: ({ data }) => {
        const productIds = data.productList.products.map(
          (product: ProductList) => product.erpPartNumber
        );
        callGetAssociatedLists({ products: productIds });
      }
    },
    { orderNumber: id, erpName, billTo }
  );

  // 🟣 Lazy API - get proof of delivery
  const { call: callProofOfDelivery, loading: podLoading } =
    useApiGetProofOfDelivery({
      onCompleted: ({ data }) => setProofOfDelivery(data)
    });

  /**
   * Memo
   */
  // 🔵 Memo - Is a Backordered item & order & invoice data set
  const orderData = useMemo(() => {
    const selectedOrder: Order = {
      amount: null,
      billToName: billTo || null,
      contractNumber: data?.contractNumber ?? dataInvoice?.contractNumber,
      creditCard: null,
      customerPO:
        data?.orderInformation?.poNumber ??
        dataInvoice?.orderInformation?.poNumber,
      deliveryMethod:
        data?.deliverySummary?.fulfillmentMethod ??
        dataInvoice?.deliverySummary?.fulfillmentMethod,
      handling:
        Number(
          data?.orderSummary?.handling ?? dataInvoice?.orderSummary?.handling
        ) || 0,
      invoiceNumber: id || null,
      jobNumber: null,
      lineItems:
        data?.productList.products || dataInvoice?.productList.products,
      orderDate:
        data?.orderInformation?.orderDate ??
        dataInvoice?.orderInformation?.orderDate,
      orderNumber: id,
      orderStatus:
        data?.orderInformation?.orderStatus ??
        dataInvoice?.orderInformation?.orderStatus,
      orderTotal:
        data?.orderSummary?.orderTotal ?? dataInvoice?.orderSummary?.orderTotal,
      orderedBy:
        data?.orderInformation?.orderedBy ??
        dataInvoice?.orderInformation?.orderedBy,
      shipAddress:
        data?.deliverySummary?.orderAddress ||
        dataInvoice?.deliverySummary?.orderAddress,
      shipDate:
        data?.deliverySummary?.fulfilledDate ??
        dataInvoice?.deliverySummary?.fulfilledDate,
      shipToId: selectedAccounts.shipTo?.id,
      shipToName:
        data?.orderInformation?.jobName ??
        dataInvoice?.orderInformation?.jobName,
      shippingAmount:
        Number(
          data?.orderSummary?.shipping ?? dataInvoice?.orderSummary?.shipping
        ) || 0,
      specialInstructions:
        data?.deliverySummary?.specialInstructions ??
        dataInvoice?.deliverySummary?.specialInstructions,
      subTotal: Number(
        data?.orderSummary?.subTotal ?? dataInvoice?.orderSummary?.subTotal
      ),
      tax: Number(data?.orderSummary?.tax ?? dataInvoice?.orderSummary?.tax),
      terms: null,
      webStatus:
        data?.orderInformation?.orderStatus ??
        dataInvoice?.orderInformation?.orderStatus
    };

    const backOrdered = Boolean(
      selectedOrder?.lineItems?.filter((i) => Boolean(i.backOrderedQuantity))
        ?.length
    );
    return { selectedOrder, backOrdered };
  }, [data, dataInvoice, billTo, id, selectedAccounts]);

  /**
   * Callbacks
   */
  // 🟤 Cb - handle navigation in embedded session
  const handleEmbeddedNavigation = (path: string) => {
    if (!authState?.isAuthenticated) {
      // embedded session but authState is not updated - update auth then go to orders
      oktaAuth?.authStateManager
        .updateAuthState()
        .then(() => history.push(path));
    } else {
      // embedded and already authenticated - go to orders
      history.push(path);
    }
  };

  // 🟤 Cb - handle proof of delivery modal open
  const handleProofOfDeliveryModalOpen = (open: boolean) => () =>
    setProofOfDeliveryModalOpen(open);

  // 🟤 Cb - navigate back to orders page
  const backToOrdersPage = () => {
    if (embedding()) {
      handleEmbeddedNavigation('/orders');
    } else {
      // normal navigation back to orders (non-embedded)
      history.push(`/orders/${localStorage.getItem('filters')}`);
    }
  };
  /**
   * Render
   */
  if (loading || loadingInvoice) {
    return <Loader size="flex" className="my-16 h-[240px]" />;
  }
  return (
    <div className="max-w-7xl px-4 mx-auto md:mx-0 md:px-0">
      {!embedding() && (
        <Breadcrumbs
          pageTitle={t('cart.orderDetails')}
          config={[breadcrumbConfig]}
          newMobileStyle
          className="md:pl-6"
        />
      )}
      {/** Back and Print buttons section START */}
      <div className="flex mb-4 px-6" data-testid="order-details-back-link">
        <Link onClick={backToOrdersPage} className=" cursor-pointer">
          <ArrowBack />
          <span
            className="text-base font-medium pl-2"
            data-testid="order-details-back-text"
          >
            {t('common.back')}
          </span>
        </Link>
        <button
          className="p-2 mx-5 my-2 border bg-common-white rounded hidden font-semibold float-right"
          data-testid="order-details-print-button"
        >
          <PrintIcon />
          <span className="p-1">{t('common.print')}</span>
        </button>
      </div>
      {/** Back button section end */}
      {(called || callInvoice) && (
        /** 1st SECTION: Order Status, Delivery and Order Summary */
        <div className="grid grid-cols-3 gap-8 px-6 sm:grid-cols-1">
          <ProofOfDeliveryModal
            open={proofOfDeliveryModalOpen}
            onClose={handleProofOfDeliveryModalOpen(false)}
            pod={proofOfDelivery}
            podLoading={podLoading}
            data-testid="order-detail-pod-modal"
          />
          <OrderDetailsStatus
            orderNumber={id}
            content={data?.orderInformation || dataInvoice?.orderInformation}
            podAvailable={
              data?.deliverySummary?.podStatus! ||
              dataInvoice?.deliverySummary?.podStatus!
            }
            handleProofOfDelivery={handleProofOfDelivery}
          />
          <OrderDetailsDelivery
            content={data?.deliverySummary || dataInvoice?.deliverySummary}
            orderNumber={id}
            podAvailable={
              data?.deliverySummary?.podStatus! ||
              dataInvoice?.deliverySummary?.podStatus!
            }
            handleProofOfDelivery={handleProofOfDelivery}
          />
          <OrderDetailsSummary
            content={data?.orderSummary || dataInvoice?.orderSummary}
            customerPO={
              data?.orderInformation?.poNumber ??
              dataInvoice?.orderInformation?.poNumber
            }
            productOrder={orderData.selectedOrder}
            productPricing={
              (data?.productPricing?.products ||
                dataInvoice?.productPricing?.products) ??
              []
            }
          />
        </div>
      )}
      {(called || callInvoice) && (
        /** 2nd SECTION: Product Details and Linked Orders */
        <div className="grid grid-cols-3 gap-8 mt-8 mb-8 px-6 sm:grid-cols-1">
          {/** Product Detail section START */}
          <Hidden mdUp>
            {orderData.backOrdered && (
              <div
                className="px-6 py-2 pt-4 mb-4 text-sm rounded-md bg-warning-20 border-2 border-secondary-1-100"
                role="alert"
              >
                <div className="inline-flex">
                  <WarningIcon
                    className="text-secondary-1-100"
                    width={80}
                    height={80}
                  />
                  <span className="mt-0.5 px-4 pb-2 text-base font-bold">
                    {t('orders.backOrderedWarningText')}
                  </span>
                </div>
              </div>
            )}
            <ProductsCardMobile
              loading={loading || loadingInvoice}
              order={orderData.selectedOrder}
              isMincron={isMincron}
              pricingData={
                (data?.productPricing?.products ||
                  dataInvoice?.productPricing?.products) ??
                []
              }
              fromOrders
            />
          </Hidden>
          <Hidden mdDown>
            {orderData.backOrdered && (
              <div
                className="px-6 py-4 text-sm rounded-md bg-warning-20 border-2 border-secondary-1-100 col-span-2"
                role="alert"
              >
                <div className="inline-flex">
                  <WarningIcon className="text-secondary-1-100" />
                  <span className="px-4 text-base font-bold">
                    {t('orders.backOrderedWarningText')}
                  </span>
                </div>
              </div>
            )}
            <div className="col-span-2">
              <ProductsCard
                loading={loading || loadingInvoice}
                order={orderData.selectedOrder}
                isMincron={isMincron}
                pricingData={
                  (data?.productPricing?.products ||
                    dataInvoice?.productPricing?.products) ??
                  []
                }
                fromOrders
              />
            </div>
          </Hidden>
          {
            /** Product Details END */
            /** Linked Orders START */
            erpName === ErpSystemEnum.Eclipse && (
              <OrderDetailsRelated
                content={
                  (data?.linkedOrders?.linkedOrders ||
                    dataInvoice?.linkedOrders?.linkedOrders) ??
                  []
                }
              />
            )
            /** Linked Orders END */
          }
        </div>
      )}
    </div>
  );

  // 🔣 util - handle page change
  function handleProofOfDelivery(invoiceNumber: string) {
    callProofOfDelivery({ invoiceNumber, erpName });
    setProofOfDeliveryModalOpen(true);
  }
}

export default OrderDetails;

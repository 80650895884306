import { useCallback, useEffect, useMemo, useState } from 'react';

import { Hidden, Skeleton } from '@mui/material';
import { subMonths } from 'date-fns';
import { DateRange as DateRangeType } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'AuthProvider';
import { useEmbeddedContext } from 'providers/EmbeddedProvider';
import {
  useApiExportOrdersCSV,
  useApiGetOrders,
  useApiGetProofOfDelivery,
  useApiPrintOrders
} from 'API/orders.api';
import {
  GetOrdersRequest,
  GetOrdersResponse,
  Order
} from 'API/types/orders.types';
import DateRange from 'common/DateRange';
import SearchCard from 'common/SearchCard';
import { Loader } from 'components';
import Header from 'pages/Header';
import OrdersMobileRenderer from 'pages/Orders/util/OrdersMobileRenderer';
import TableLoading from 'pages/Orders/util/TableLoading';
import {
  ErpSystemEnum,
  Maybe,
  ProofOfDeliveryResponse
} from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useOrdersQueryParam } from 'pages/Orders/util/queryParam';
import TableRenderer from 'pages/Orders/util/TableRenderer';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { formatDate } from 'utils/dates';
import trimSpaces from 'utils/trimSpaces';
import { OrdersColumnNames } from 'pages/Orders/util/lib';
import { downloadFile } from 'utils/downloadFile';
import ProofOfDeliveryModal from 'Invoice/ProofOfDeliveryModal';
import { embedding } from 'utils/embedPage';

/**
 * Const
 */
export const defaultRange = {
  from: subMonths(new Date(), 1),
  to: new Date()
};

/**
 * Component
 */
function NewOrders() {
  /**
   * Custom hooks
   */
  const [queryParam, setQueryParams] = useOrdersQueryParam();
  const { searchBy, from, to, page, sortBy, orderStatus } = queryParam;
  const { t } = useTranslation();
  useDocumentTitle(t('common.myOrders'));

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const { oktaAuth, authState } = useAuthContext();
  const { embeddedSession } = useEmbeddedContext();

  const erpName = selectedAccounts?.erpSystemName ?? ErpSystemEnum.Eclipse;
  const shipTo = selectedAccounts?.shipTo?.erpAccountId ?? '';
  const billTo = selectedAccounts?.billTo?.erpAccountId ?? '';

  /**
   * State
   */
  const initialRange: DateRangeType = {
    from: new Date(from),
    to: new Date(to)
  };
  const [range, setRange] = useState<DateRangeType>(initialRange);
  const [searchValue, setSearchValue] = useState(searchBy);
  const [displayCount, setDisplayCount] = useState('25');
  const [pageNo, setPageNo] = useState(page);
  const [sort, setSort] = useState(sortBy);
  const [ordersStatus, setOrdersStatus] = useState(orderStatus);
  const [ordersForBillTo, setOrdersForBillTo] = useState('');
  const [ordersForShipTo, setOrdersForShipTo] = useState('');
  const [ordersApiData, setOrdersApiData] = useState<GetOrdersResponse>();
  const [proofOfDelivery, setProofOfDelivery] =
    useState<ProofOfDeliveryResponse>();
  const [proofOfDeliveryModalOpen, setProofOfDeliveryModalOpen] =
    useState(false);

  /**
   * API
   */
  // 🟣 Lazy API - get orders
  const { call: getOrders, loading: ordersLoading } = useApiGetOrders({
    onCompleted: ({ data }) => setOrdersApiData(data),
    onError: () => setOrdersApiData(undefined)
  });
  // 🟣 Lazy API - Export Orders
  const { call: callExportOrdersCSV } = useApiExportOrdersCSV({
    onCompleted: ({ data }) => generateExportOrdersCSV(data)
  });
  // 🟣 Lazy API - get proof of delivery
  const { call: callProofOfDelivery } = useApiGetProofOfDelivery({
    onCompleted: ({ data }) => setProofOfDelivery(data)
  });
  // 🟣 Lazy API - print orders
  const { call: printOrders, loading: printOrdersLoading } = useApiPrintOrders({
    onCompleted: ({ data }) => generateExportOrdersPDF(data)
  });

  /**
   * Memo
   */
  // 🔵 Memo - ordersData
  const ordersData = useMemo(
    () => (ordersApiData?.orders ?? []) as Order[],
    [ordersApiData]
  );

  const ordersResponse = useMemo(
    () => ordersApiData as GetOrdersResponse,
    [ordersApiData]
  );

  const sortByObj = useMemo(
    () =>
      sort.map((s) => ({
        id: s.replace('!', ''),
        direction: s.includes('!')
      })),
    [sort]
  );

  /**
   * Callbacks
   */
  // 🟤 CB - Reset Search
  const onReset = () => {
    setSearchValue('');
    setQueryParams({ searchBy: '' });
    const statusFilter =
      !orderStatus || orderStatus === 'All'
        ? {}
        : { statusFilter: orderStatus };
    callGetOrders({ searchBy: '', ...statusFilter });
  };

  // 🟤 CB - callGetOrders
  const callGetOrders = useCallback(
    (override?: Partial<GetOrdersRequest>) =>
      shipTo &&
      billTo &&
      erpName &&
      page &&
      from &&
      to &&
      displayCount &&
      sortByObj &&
      getOrders({
        shipTo,
        billTo,
        startDate: formatDate(from),
        endDate: formatDate(to),
        erpName,
        page: page ?? '1',
        perPage: displayCount,
        searchBy: searchBy,
        sortBy: sortByObj[0].id,
        sortDirection: sortDirectionString(sortByObj[0].direction),
        ...override
      }),
    [
      shipTo,
      billTo,
      erpName,
      page,
      searchBy,
      sortByObj,
      from,
      to,
      getOrders,
      displayCount
    ]
  );

  // 🟤 CB - Apply Search Result
  const onViewResults = useCallback(
    (
      dateRange?: DateRangeType,
      perPage?: string,
      pageNo?: string,
      sortColumn?: string,
      sortDirection?: boolean,
      status?: string
    ) => {
      const trimmedSearchValue = trimSpaces(searchValue);
      setSearchValue(trimmedSearchValue);

      const myDates = {
        from: formatDate(dateRange?.from ?? range.from),
        to: formatDate(dateRange?.to ?? range.to)
      };

      setQueryParams({
        searchBy: trimmedSearchValue,
        sortBy: sort,
        orderStatus: status ?? ordersStatus,
        ...myDates
      });
      const statusFilter =
        status === 'All' || (!status && ordersStatus === 'All')
          ? {}
          : { statusFilter: status ?? ordersStatus };

      !pageNo && setPageNo('1');
      callGetOrders({
        startDate: myDates.from,
        endDate: myDates.to,
        searchBy: trimmedSearchValue,
        perPage: perPage ?? displayCount,
        page: pageNo ?? '1',
        sortBy: sortColumn ?? sortByObj[0].id,
        sortDirection: sortDirectionString(
          sortDirection ?? sortByObj[0].direction
        ),
        ...statusFilter
      });
    },
    [
      displayCount,
      ordersStatus,
      searchValue,
      sort,
      sortByObj,
      range,
      setQueryParams,
      callGetOrders
    ]
  );

  // 🟤 CB - Set date range
  const setDateRange = (dateRange: DateRangeType) => {
    setRange(dateRange);
  };

  // 🟤 Cb - handle generating the orders csv
  const handleExportOrdersCSV = () => {
    callExportOrdersCSV({
      shipTo,
      billTo,
      startDate: formatDate(from),
      endDate: formatDate(to),
      erpName,
      searchBy: searchBy,
      sortBy: sortByObj[0].id,
      sortDirection: sortByObj[0].direction ? 'asc' : 'desc',
      ...(orderStatus !== 'All' && { statusFilter: orderStatus })
    });
  };

  // 🟤 Cb - handle generating the orders csv
  const generateExportOrdersCSV = (response: BlobPart) =>
    downloadFile(
      response,
      `${billTo}_${formatDate(new Date(), 'yyyy-MM-dd')}`,
      'text/csv'
    );

  // 🟤 Cb - handle proof of delivery modal open
  const handleProofOfDeliveryModalOpen = (open: boolean) => () =>
    setProofOfDeliveryModalOpen(open);

  // 🟤 Cb - callPrintOrders
  const callPrintOrders = () => {
    printOrders({
      companyName: selectedAccounts?.shipToErpAccount?.companyName ?? '',
      startDate: formatDate(from),
      endDate: formatDate(to),
      searchBy: searchBy,
      sortBy: sortByObj[0].id,
      sortDirection: sortByObj[0].direction ? 'asc' : 'desc',
      ...(orderStatus !== 'All' && { statusFilter: orderStatus })
    });
  };

  // 🟤 Cb - handle generating the orders pdf
  const generateExportOrdersPDF = (response: BlobPart) => {
    const blob = new Blob([response], { type: 'application/pdf' });

    const url = window.URL.createObjectURL(blob);
    if (url) {
      window.open(url);
      window.URL.revokeObjectURL(url);
    }
  };

  // 🟤 Cb - reset date range
  const resetRange = useCallback(() => {
    setRange({ ...defaultRange });
    onViewResults({ ...defaultRange });
  }, [setRange, onViewResults]);

  /**
   * Effect
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // 🟡 Effect - Get Orders on load
  useEffect(() => {
    if (ordersForBillTo !== billTo || ordersForShipTo !== shipTo) {
      setOrdersForBillTo(billTo);
      setOrdersForShipTo(shipTo);
      const statusFilter =
        !orderStatus || orderStatus === 'All'
          ? {}
          : { statusFilter: orderStatus };
      callGetOrders({ ...statusFilter });
    }
  }, [
    billTo,
    ordersForBillTo,
    ordersForShipTo,
    orderStatus,
    shipTo,
    callGetOrders
  ]);

  useEffect(() => {
    const autoLoadEmbeddedOrders = () => {
      if (!embeddedSession) {
        return;
      }
      if (!authState?.isAuthenticated) {
        oktaAuth?.authStateManager.updateAuthState().then(() => resetRange());
      }
    };
    autoLoadEmbeddedOrders();
  }, [
    resetRange,
    embeddedSession,
    oktaAuth?.authStateManager,
    authState?.isAuthenticated
  ]);

  /**
   * Render
   */
  return (
    <>
      {printOrdersLoading && <Loader />}
      {!embedding() && <Header pageTitle={t('common.orders')} />}
      <Hidden mdDown>
        <div className="container bg-common-white mx-auto !my-0">
          {/** SEARCH AND RESULTS TABLE SECTION START */}
          <div className="container mx-auto lg">
            <div className="relative overflow-x-auto pt-6 pb-4 px-6 md:!px-0">
              <DateRange
                value={range}
                onChange={setDateRange}
                onClear={resetRange}
                singleField
                viewResults={onViewResults}
              >
                <SearchCard
                  placeholder={t('orders.orderSearchPlaceholder')}
                  search={searchValue}
                  setSearch={setSearchValue}
                  displayCount={displayCount}
                  setDisplayCount={handleDisplayCountChange}
                  onReset={onReset}
                  onExportClicked={handleExportOrdersCSV}
                  onViewResultsClicked={onViewResults}
                  resultsCount={ordersApiData?.totalItems}
                  testId="search-orders-input"
                  newOrdersPage
                  filterValue={ordersStatus}
                  setFilterValue={handleStatusChange}
                  callPrintOrders={callPrintOrders}
                />
              </DateRange>
            </div>
            <div className="px-6">
              <div className="border rounded relative overflow-x-auto">
                {ordersLoading ? (
                  <TableLoading
                    headers={OrdersColumnNames}
                    rowClassName="bg-common-white border-b"
                    rowCount={5}
                    columnClassName="px-6 py-4"
                    data-testid="orders-table-loader"
                  />
                ) : (
                  <>
                    <ProofOfDeliveryModal
                      open={proofOfDeliveryModalOpen}
                      onClose={handleProofOfDeliveryModalOpen(false)}
                      pod={proofOfDelivery}
                      data-testid="orders-pod-modal"
                    />
                    <TableRenderer
                      headers={OrdersColumnNames}
                      content={ordersData}
                      getOrdersResponse={ordersResponse}
                      page={pageNo}
                      handlePageChange={handlePageChange}
                      sortBy={sortByObj?.[0]}
                      handleSortChange={handleSortChange}
                      handleProofOfDelivery={handleProofOfDelivery}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-common-white py-4"></div>
      </Hidden>
      <Hidden mdUp>
        <div className="container bg-common-white mx-auto !my-0">
          <div className="relative overflow-x-auto pt-2 pb-1 !px-0">
            <DateRange
              value={range}
              onChange={setDateRange}
              onClear={resetRange}
              singleField
              viewResults={onViewResults}
            >
              <SearchCard
                placeholder={t('orders.orderSearchPlaceholder')}
                search={searchValue}
                setSearch={setSearchValue}
                displayCount={displayCount}
                setDisplayCount={handleDisplayCountChange}
                onReset={onReset}
                onExportClicked={handleExportOrdersCSV}
                onViewResultsClicked={onViewResults}
                resultsCount={ordersApiData?.totalItems}
                testId="search-orders-input"
                newOrdersPage
                filterValue={ordersStatus}
                setFilterValue={handleStatusChange}
                sortBy={sortByObj?.[0]}
                handleSortChange={handleSortChange}
                callPrintOrders={callPrintOrders}
                printOrdersLoading={printOrdersLoading}
              />
            </DateRange>
          </div>
          <div>
            <div className="relative overflow-x-auto">
              {ordersLoading ? (
                <div>
                  <div className="py-6" />
                  <div
                    className="w-full bg-common-white border rounded shadow-surround py-8"
                    data-testid="order-card-skeleton"
                  >
                    <div className="px-4 py-20">
                      <Loader />
                      <Skeleton />
                    </div>
                  </div>
                </div>
              ) : (
                <OrdersMobileRenderer
                  orders={ordersData}
                  getOrdersResponse={ordersResponse}
                  page={pageNo}
                  handlePageChange={handlePageChange}
                  onExportClicked={handleExportOrdersCSV}
                />
              )}
            </div>
          </div>
        </div>
      </Hidden>
    </>
  );

  /**
   * Util
   */

  // 🔣 util - handle perpage display count
  function handleDisplayCountChange(perPage: string) {
    setDisplayCount(perPage);
    onViewResults(undefined, perPage);
  }
  // 🔣 util - handle page change
  function handlePageChange(currentPage: string) {
    setPageNo(currentPage);
    onViewResults(undefined, undefined, currentPage);
  }
  // 🔣 util - handle page change
  function sortDirectionString(input?: Maybe<boolean>) {
    return input ? 'asc' : 'desc';
  }
  // 🔣 util - handle sort change
  function handleSortChange(
    sortColumn: { id: string; direction: boolean },
    currentPage: string
  ) {
    const sortVal = [(sortColumn.direction ? '!' : '') + sortColumn.id];
    setSort(sortVal);
    setPageNo(currentPage);
    onViewResults(
      undefined,
      undefined,
      currentPage,
      sortColumn.id,
      sortColumn.direction
    );
  }

  // 🔣 util - handle page change
  function handleStatusChange(status: string) {
    setOrdersStatus(status);

    onViewResults(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      status
    );
  }

  // 🔣 util - handle page change
  function handleProofOfDelivery(invoiceNumber: string) {
    callProofOfDelivery({ invoiceNumber, erpName });
    setProofOfDeliveryModalOpen(true);
  }
}

export default NewOrders;

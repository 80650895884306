import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';
import { DeliveryIcon, InStockIcon, QuickOrder } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import { useBranchContext } from 'providers/BranchProvider';

/**
 * Config
 */
const containerStyling =
  'flex items-center w-[256px] h-14 p-2 rounded-sm shadow-lg md:w-full';
const primaryTextStyling = 'text-base font-medium ml-2 md:ml-3';
const secondaryTextStyling =
  'w-[195px] h-4 ml-2 text-primary-3-100 text-xs font-normal md:ml-3';

/**
 * Component
 */
function ProductAvailabilityChip() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const { shippingBranchLoading, shippingBranch } = useBranchContext();
  const { product, loading } = useProductPageContext();
  const stock =
    (shippingBranch?.isPricingOnly
      ? product?.totalAvailableQty
      : product?.branchAvailableQty) ?? 0;
  const displayStock = stock > 999 ? '999+' : stock;

  /**
   * Render
   */
  // 🟩 logged out
  if (!authState?.isAuthenticated) {
    return null;
  }
  // 🟩 loading
  if (loading || shippingBranchLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height={56}
        width={256}
        data-testid="stock-availability-chip-loading"
      />
    );
  }
  // 🟩 Delivery
  if (stock && shippingBranch?.isPricingOnly) {
    return (
      <div
        className={clsx(containerStyling, 'bg-primary-2-10')}
        data-testid="stock-availability-chip"
      >
        <DeliveryIcon
          className="w-8 h-8 text-primary-1-100"
          data-testid="delivery-icon"
        />
        <div className="flex flex-col">
          <p className={clsx(primaryTextStyling, 'text-primary-1-100')}>
            {`${displayStock} ${t('common.readyToShip')}`}
          </p>
          <p className={secondaryTextStyling}>
            {t('common.shippingTo')} {shippingBranch.zip}
          </p>
        </div>
      </div>
    );
  }
  // 🟩 In stock
  if (stock) {
    return (
      <div
        className={clsx(containerStyling, 'bg-success-10')}
        data-testid="stock-availability-chip"
      >
        <InStockIcon
          className="w-8 h-8 text-success-100"
          data-testid="in-stock-icon"
        />
        <div className="flex flex-col">
          <p className={clsx(primaryTextStyling, 'text-success-100')}>
            {`${displayStock} ${t('common.inStock')}`}
          </p>
          <p className={secondaryTextStyling}>
            {`${t('common.at')} ${shippingBranch?.name}, ${
              shippingBranch?.city
            }`}
          </p>
        </div>
      </div>
    );
  }
  // 🟩 Available for order
  return (
    <div
      className={clsx(containerStyling, 'bg-secondary-4-100')}
      data-testid="stock-availability-chip"
    >
      <QuickOrder
        className="w-8 h-8 text-primary-3-100"
        data-testid="order-icon"
      />
      <div className="flex flex-col">
        <p className={clsx(primaryTextStyling, 'text-primary-3-100')}>
          {t('common.availableForOrder')}
        </p>
        <p className={secondaryTextStyling}>
          {t('common.stockAvailabilityInfo')}
        </p>
      </div>
    </div>
  );
}
export default ProductAvailabilityChip;

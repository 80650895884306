import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import {
  GetCheckoutResponse,
  RapidDeliveryRequest,
  RapidDeliveryResponse,
  UpdateDeliveryRequest
} from 'API/types/checkout.types';
import { OrderPreviewResponse, OrderResponse } from 'generated/graphql';

/**
 * Hooks
 */
// 🟩 POST /account/rapidDeliveryCheck - Check Rapid Delivery availability for checkout
export function useApiRapidDeliveryCheck(
  options: APIOptions<RapidDeliveryResponse> = {}
) {
  const props: UseAPILazyProps<RapidDeliveryResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<RapidDeliveryResponse>(props);

  const call = async (cartId: string, body: RapidDeliveryRequest) =>
    await api.call({
      ...props,
      url: `account/rapidDeliveryCheck/${cartId}`,
      body
    });
  return { ...api, call };
}
// 🟦 GET /checkout/getCheckout - Get Checkout
export function useApiGetCheckout(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string) =>
    await api.call({
      ...props,
      url: `checkout/getCheckout/${cartId}`
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateIsRapid - update isRapid
export function useApiUpdateIsRapid(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string, deliveryType: string) =>
    await api.call({
      ...props,
      url: `checkout/updateIsRapid/${cartId}`,
      header: {
        deliveryType: deliveryType === 'Rapid'
      }
    });
  return { ...api, call };
}
// 🟦 GET /checkout/getCheckoutTotals - Get Checkout Totals
export function useApiGetCheckoutTotals(
  options: APIOptions<OrderPreviewResponse> = {}
) {
  const props: UseAPILazyProps<OrderPreviewResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<OrderPreviewResponse>(props);

  const call = async (cartId: string) =>
    await api.call({
      ...props,
      url: `checkout/getCheckoutTotals/${cartId}`
    });
  return { ...api, call };
}
// 🟩 POST /checkout/checkoutSubmit - submit checkout
export function useApiCheckoutSubmit(options: APIOptions<OrderResponse> = {}) {
  const props: UseAPILazyProps<OrderResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<OrderResponse>(props);

  const call = async (cartId: string) =>
    await api.call({
      ...props,
      url: `checkout/submit/${cartId}`
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateDeliveryInstructions - Update Delivery Instructions
export function useApiUpdateDeliveryInstructions(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string, instructions: string) =>
    await api.call({
      ...props,
      url: `checkout/updateDeliveryInstructions/${cartId}`,
      header: { instructions }
    });
  return { ...api, call };
}
// 🟩 POST /checkout/updateDeliveryAddress - Update Delivery Address
export function useApiUpdateDeliveryAddress(
  options: APIOptions<GetCheckoutResponse> = {}
) {
  const props: UseAPILazyProps<GetCheckoutResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetCheckoutResponse>(props);

  const call = async (cartId: string, body: UpdateDeliveryRequest) =>
    await api.call({
      ...props,
      url: `checkout/updateDeliveryAddress/${cartId}`,
      body
    });
  return { ...api, call };
}

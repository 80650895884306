import { useMemo } from 'react';

import { useAuthContext } from 'AuthProvider';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import AddToListButton from 'common/AddToListButton';
import { Maybe } from 'generated/graphql';
import { useLocalStorage } from 'hooks/useLocalStorage';

/**
 * Component
 */
function ProductAddToListButton() {
  /**
   * Context
   */
  const { authState } = useAuthContext();
  const { availableInList, product, quantity, setAvailableInList } =
    useProductPageContext();
  const [listAddedIds] = useLocalStorage<Maybe<string[]>>(
    'itemsAddedList',
    null
  );

  /**
   * Memo
   */
  const existId = useMemo(
    () => listAddedIds?.includes?.(product?.productId ?? ''),
    [listAddedIds, product?.productId]
  );

  /**
   * Render
   */
  if (!authState?.isAuthenticated) {
    return null;
  }
  return (
    <AddToListButton
      availableInList={availableInList}
      updatedAddedToLists={setAvailableInList}
      partNumber={product?.productId ?? ''}
      quantity={quantity}
      isAddedToList={existId}
    />
  );
}
export default ProductAddToListButton;

import { createContext, useCallback, useContext, useState } from 'react';

import { AxiosResponse } from 'axios';
import { Drawer } from '@mui/material';
import { noop } from 'lodash-es';
import { Trans, useTranslation } from 'react-i18next';
import { Maybe } from 'yup/lib/types';

import { WrapperProps } from '@reece/global-types';
import {
  useApiAddProductsToList,
  useApiGetAssociatedLists,
  useApiRemoveProductMultipleLists
} from 'API/lists.api';
import {
  AssociatedLists,
  List,
  ProductBasicInfo,
  ProductsIds,
  ProductsListsResponse
} from 'API/types/lists.types';
import AddToListDrawer from 'common/ListsDrawer';
import { Link } from 'components';
import baseI18nComponents from 'locales/baseComponents';
import { useToastContext } from 'providers/ToastProvider';
import { asyncNoop, asyncNoopOutput } from 'utils/etc';

/**
 * Config
 */
export const MAX_LIST_ITEMS = 600;

/**
 * Types
 */
export type ListsContextType = {
  updateAssociatedLists: (p: ProductBasicInfo[], l: SelectedListsMap) => void;
  addDrawerOpen: boolean;
  addProductLoading: boolean;
  addProductToLists: (
    p?: ProductBasicInfo[],
    l?: SelectedListsMap
  ) => Promise<boolean>;
  callGetAssociatedLists: (
    body: ProductsIds
  ) => Promise<Maybe<AxiosResponse<Maybe<ProductsListsResponse>>> | undefined>;
  openAddToListDrawer: (products: ProductBasicInfo[]) => void;
  removeProductFromLists: (
    lists: string[],
    name?: string,
    removedAll?: boolean
  ) => void;
  savedSelectedLists: SelectedListsMap;
  selectedAssociatedLists: string[];
  selectedLists: SelectedListsMap;
  selectedProducts: ProductBasicInfo[];
  setAddDrawerOpen: (p: boolean) => void;
  setSavedSelectedLists: (p: SelectedListsMap) => void;
  setSelectedLists: (p: SelectedListsMap) => void;
  setSelectedProducts: (p: ProductBasicInfo[]) => void;
  associatedLists: AssociatedLists[];
  setAssociatedLists: React.Dispatch<React.SetStateAction<AssociatedLists[]>>;
};
export type SelectedListsMap = {
  [listId: string]: List;
};

/**
 * Context
 */
export const defaultListsContext: ListsContextType = {
  updateAssociatedLists: noop,
  addDrawerOpen: false,
  addProductLoading: false,
  addProductToLists: asyncNoopOutput(false),
  callGetAssociatedLists: asyncNoop,
  openAddToListDrawer: noop,
  removeProductFromLists: noop,
  savedSelectedLists: {},
  selectedLists: {},
  setAddDrawerOpen: noop,
  selectedProducts: [],
  selectedAssociatedLists: [],
  setSavedSelectedLists: noop,
  setSelectedLists: noop,
  setSelectedProducts: noop,
  associatedLists: [],
  setAssociatedLists: noop
};
export const ListsContext = createContext(defaultListsContext);
export const useListsContext = () => useContext(ListsContext);

/**
 * Provider
 */
function ListsProvider({ children }: WrapperProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Contexts
   */
  const { toast } = useToastContext();

  /**
   * States
   */
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<ProductBasicInfo[]>(
    []
  );
  const [selectedLists, setSelectedLists] = useState<SelectedListsMap>({});
  const [savedSelectedLists, setSavedSelectedLists] =
    useState<SelectedListsMap>({});

  const [associatedLists, setAssociatedLists] = useState<AssociatedLists[]>([]);

  const [selectedAssociatedLists, setSelectedAssociatedLists] = useState<
    string[]
  >([]);

  /**
   * API
   */
  // 🟣 Lazy API - add product to list
  const { call: callAddProductsToList, loading: addProductLoading } =
    useApiAddProductsToList();
  // 🟣 Lazy API - get associated lists
  const { call: callGetAssociatedLists } = useApiGetAssociatedLists({
    onCompleted: (res) => setAssociatedLists(res.data.asscProductToList)
  });
  // 🟣 Lazy API - get associated lists
  const { call: callRemoveProductFromLists } =
    useApiRemoveProductMultipleLists();

  /**
   * Callbacks
   */
  // 🟤 Cb - add products to lists
  const addProductToLists = async (
    myProducts?: ProductBasicInfo[],
    mySelectedLists?: SelectedListsMap
  ) => {
    if (!myProducts?.length && !selectedProducts.length) {
      return false;
    }
    const lists = mySelectedLists ?? selectedLists;
    const products = myProducts?.length ? myProducts : selectedProducts;
    const listId = Object.keys(lists);
    return new Promise<boolean>((resolve) => {
      callAddProductsToList({ listId, products })
        .then((res) => {
          const isSuccess = res?.status === 200;
          resolve(isSuccess);
          if (!isSuccess) {
            return;
          }
          updateAssociatedLists(products, lists);
          const list = Object.values(lists)[0];
          const message = (
            <Trans
              i18nKey={
                // istanbul ignore next
                products.length === 1
                  ? 'lists.addToListSuccess'
                  : 'lists.addToListSuccessItems'
              }
              values={{
                name: list.name,
                count: listId.length,
                itemCount: products.length
              }}
              components={{
                ...baseI18nComponents,
                a: <Link to={`/lists?id=${list.id}`} />
              }}
            />
          );
          const button = {
            display: t('common.edit'),
            action: openAddToListDrawer
          };
          toast({ message, button, kind: 'success' });
          setSelectedLists({});
          setAddDrawerOpen(false);
        })
        .catch(() => {
          toast({ message: t('lists.addToListError'), kind: 'error' });
          resolve(false);
        });
    });
  };
  // 🟤 Cb - Remove products from lists
  const removeProductFromLists = (
    listsToBeRemoved: string[],
    listName?: string,
    removedAll?: boolean
  ) => {
    if (!selectedProducts.length || !listsToBeRemoved.length) {
      return;
    }
    const products = selectedProducts.map(({ productId }) => productId);
    callRemoveProductFromLists({ listId: listsToBeRemoved, products })
      .then((res) => {
        const isSuccess = res?.status === 200;
        if (isSuccess) {
          const count = listsToBeRemoved.length;
          const message = t('lists.itemRemoved', { count, listName });
          toast({ message, kind: 'info' });
        }
        if (removedAll) {
          updateAssociatedLists(selectedProducts, {});
          setSelectedLists({});
          setAddDrawerOpen(false);
        }
      })
      .catch(() => toast({ message: t('lists.networkError'), kind: 'error' }));
  };
  // 🟤 Cb - Get array of list ids associated to selected products
  const getUniqueListsIds = useCallback(
    (products: ProductBasicInfo[]) => {
      const productIds = new Set(products.map(({ productId }) => productId));
      const selectedLists = associatedLists.reduce<string[]>((prev, item) => {
        const isInProducts = productIds.has(item.productId);
        return isInProducts ? [...prev, ...item.listIds] : prev;
      }, []);

      return Array.from(new Set(selectedLists));
    },
    [associatedLists]
  );
  // 🟤 Cb - open drawer for lists call
  const openAddToListDrawer = (products?: ProductBasicInfo[]) => {
    setAddDrawerOpen(true);
    setSelectedLists(savedSelectedLists);
    products && setSelectedAssociatedLists(getUniqueListsIds(products));
  };
  // 🟤 Cb - Add items to associated lists
  const updateAssociatedLists = useCallback(
    (products: ProductBasicInfo[], lists: SelectedListsMap) => {
      // Extract listIds using map
      const listIds = Object.keys(lists).map((listId) => lists[listId].id);
      const updatedAssociatedLists = associatedLists.reduce<AssociatedLists[]>(
        (prev, item) => {
          const product = products.find((p) => p.productId === item.productId);
          product ? prev.push({ ...item, listIds }) : prev.push(item);
          return prev;
        },
        []
      );
      setAssociatedLists(updatedAssociatedLists);
    },
    [associatedLists]
  );

  /**
   * Render
   */
  return (
    <ListsContext.Provider
      value={{
        updateAssociatedLists,
        addDrawerOpen,
        addProductToLists,
        addProductLoading,
        callGetAssociatedLists,
        openAddToListDrawer,
        removeProductFromLists,
        savedSelectedLists,
        selectedLists,
        selectedProducts,
        selectedAssociatedLists,
        setAddDrawerOpen,
        setSavedSelectedLists,
        setSelectedLists,
        setSelectedProducts,
        associatedLists,
        setAssociatedLists
      }}
    >
      <Drawer
        open={addDrawerOpen}
        onClose={
          // istanbul ignore next
          () => setAddDrawerOpen(false)
        }
        anchor="right"
        ModalProps={{ keepMounted: false }}
      >
        <AddToListDrawer />
      </Drawer>
      {children}
    </ListsContext.Provider>
  );
}
export default ListsProvider;

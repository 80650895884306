import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { useAuthContext } from 'AuthProvider';
import { TealiumTrackRequest } from 'API/types/tealium.types';

/**
 * Config
 */
const BASE_URL = 'tealium';

/**
 * Hooks
 */
// 🟩 POST /tealium/trackLogin - Call BFF endpoint to track login event in tealium
export function useApiTrackEvent(options: APIOptions<unknown> = {}) {
  /**
   * Context
   */
  const { oktaAuth } = useAuthContext();

  /**
   * Memo
   */
  const token = oktaAuth?.getAccessToken();

  const props: UseAPILazyProps<void> = {
    url: '',
    kind: 'post',
    options,
    header: { Authorization: `Bearer ${token}` }
  };
  const api = useApiLazyAuth<void>(props);
  const call = async (body: TealiumTrackRequest) =>
    await api.call({
      ...props,
      url: `${BASE_URL}/track`,
      body
    });
  return { ...api, call };
}

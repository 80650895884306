import { format } from 'date-fns';
import { format as formatTz } from 'date-fns-tz';
import { Row } from 'react-table';

type CompareInput = Row | Date | string;

function createDate(input: CompareInput, columnId?: string): Date {
  if (input instanceof Date) {
    return input;
  }

  return new Date(typeof input === 'string' ? input : input.values[columnId!]);
}

function compareDates(
  inputA: CompareInput,
  inputB: CompareInput,
  columnId?: string,
  desc?: boolean
) {
  const compared =
    createDate(inputA, columnId) < createDate(inputB, columnId) ? 1 : -1;

  return desc ? compared : !compared || 1;
}

function formatDate(dateString?: string | Date, pattern: string = 'P') {
  if (!dateString) {
    return '';
  }
  try {
    const date = new Date(dateString);
    return format(date, pattern);
  } catch {
    return '';
  }
}

export function newFormatDate(date: Date): string {
  const currentYear = date.getFullYear();

  const lastYear = currentYear - 1;

  date.setFullYear(lastYear);

  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
}

function formatDateTimeZone(dateString: string | Date, pattern: string = 'P') {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  return formatTz(date, pattern);
}

function getDateDifferenceInDays(
  inputA: CompareInput,
  inputB: CompareInput,
  columnId?: string
) {
  const differenceSec =
    createDate(inputA, columnId).getTime() -
    createDate(inputB, columnId).getTime();

  const difference = Math.floor(differenceSec / (1000 * 60 * 60 * 24));

  return difference;
}

export const timestamp = new Date().toISOString();

export {
  compareDates,
  formatDate,
  formatDateTimeZone,
  getDateDifferenceInDays
};

import { Fragment, useMemo } from 'react';

import {
  Box,
  Card,
  Divider,
  Grid,
  Typography
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

import LineItem from 'Invoice/LineItem';
import { Order, ProductPricing } from 'generated/graphql';
import Link from 'components/Link';

type Props = {
  loading?: boolean;
  order?: Order;
  isMincron?: boolean;
  pricingData: ProductPricing[];
  fromOrders?: boolean;
};

function ProductsCard(props: Props) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memos
   */
  const orderedPricing = useMemo(
    () =>
      props.order?.lineItems?.map((item) =>
        props.pricingData.find(
          (price) => price.productId === item?.erpPartNumber
        )
      ) ?? [],
    [props.order?.lineItems, props.pricingData]
  );

  return (
    <Card
      sx={{
        height: 1,
        '& .MuiTypography-subtitle2': {
          fontWeight: 400,
          fontSize: (theme) => theme.spacing(1.5)
        }
      }}
    >
      <Box px={4} py={3}>
        <Grid container spacing={2}>
          {props.fromOrders && (
            <Grid width={1}>
              <Box px={1} py={0.75} pt={2.5}>
                <Typography
                  color="primary"
                  fontSize={'1.25rem'}
                  lineHeight={'1.75rem'}
                  fontWeight={'bold'}
                >
                  {`${t('common.products')} (${
                    props?.order?.lineItems?.length
                  })`}
                </Typography>
                <Divider
                  sx={{
                    marginY: '0.5rem',
                    bgcolor: 'text.disabled',
                    opacity: 0.2
                  }}
                />
              </Box>
            </Grid>
          )}
          <Grid item className="printGridWidth_15">
            <Box
              className="printGridWidth_90"
              width={80}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="primary" variant="subtitle2" align="center">
                {t('common.product')}
              </Typography>
            </Box>
          </Grid>
          <Grid className="printGridWidth_25" item md={3} xs />
          <Grid className="printGridWidth_60 printGridBlock" item container md>
            <Grid item xs className="printGridWidth_25_inline">
              <Typography color="primary" variant="subtitle2">
                {t('common.price')}
              </Typography>
            </Grid>
            <Grid item xs className="printGridWidth_25_inline">
              <Typography color="primary" variant="subtitle2">
                {t('orders.orderQuantity')}
              </Typography>
            </Grid>
            <Grid item xs className="printGridWidth_25_inline">
              <Typography color="primary" variant="subtitle2">
                {t('orders.shipQuantity')}
              </Typography>
            </Grid>
            <Grid item xs className="printGridWidth_25_inline">
              <Typography
                color="primary"
                variant="subtitle2"
                align="center"
                pr={2}
              >
                {t('orders.backOrdered')}
              </Typography>
            </Grid>
            <Grid item xs className="printGridWidth_25_inline" pr={4}>
              <Typography color="primary" variant="subtitle2" align="center">
                {t('orders.orderTotal')}
              </Typography>
            </Grid>
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <Box px={2}>
        {props.loading ? (
          <LineItem loading />
        ) : props?.order?.lineItems?.length ? (
          props?.order?.lineItems?.map((p, idx) => (
            <Fragment key={p.productId ?? idx}>
              <LineItem
                loading={false}
                lineItem={p}
                isMincron={props.isMincron}
                uom={p.uom ?? orderedPricing[idx]?.orderUom}
                listIds={orderedPricing[idx]?.listIds ?? []}
                pricingData={orderedPricing[idx]}
              />
              {idx + 1 < props!.order!.lineItems!.length && <Divider />}
            </Fragment>
          ))
        ) : (
          <Box px={3} py={8}>
            <Typography
              variant="h4"
              textAlign="center"
              color="secondary03.main"
            >
              {t('orders.noItemsWaterworks')}
            </Typography>
            <Typography
              variant="h5"
              textAlign="center"
              color="secondary03.main"
            >
              {t('orders.noOrdersContactMessage')}
              <Link to="/support" className="underline">
                {t('orders.noOrdersContactBranch')}
              </Link>
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default ProductsCard;

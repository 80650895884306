import { UseAPIOnMountProps, useApiOnMount } from 'API/hooks/useApiOnMount';
import { useAuthContext } from 'AuthProvider';
import { embedding } from 'utils/embedPage';

/**
 * Hook
 */
export function useApiOnMountAuth<Response, Request = object>({
  skip,
  optionalAuth,
  ...props
}: UseAPIOnMountProps<Response, Request>) {
  /**
   * Context
   */
  const { authState, oktaAuth } = useAuthContext();

  /**
   * Memo
   */
  const token = oktaAuth?.getAccessToken();

  // istanbul ignore next
  const mySkip = !(token && (authState?.isAuthenticated || embedding()));
  // istanbul ignore next
  if (token) {
    props.header.Authorization = `Bearer ${token}`;
  }

  /**
   * API
   */
  return useApiOnMount<Response, Request>({
    ...props,
    skip: (mySkip && !optionalAuth) || skip,
    header: { ...props.header }
  });
}
